'use strict';

const scrollAnimate = require('base/components/scrollAnimate');

const handleRefinementResponse = (url, response) => {
    var $results = $(response);

    // Update browser history with Search-Show specific route
    var historyUrl = $results.find('.history-url').data('history-url');
    if (historyUrl) {
        history.replaceState(undefined, '', historyUrl);
    }
    // PLP Grid Results
    // Update DOM elements that do not require special handling
    [
        '.js-rolex-watch-filters',
        '.rolex-watch-grid',
    ].forEach((selector) => {
        updateDom($results, selector);
    });

    // PLP Category Header
    const $categoryHeaderHTML = $(response).find('.js-rolex-category-header');
    if ($categoryHeaderHTML.length) {
        updateDom($results, '.js-rolex-category-header');
    }
};

const filterAction = url => {
    var $xhr;

    // Cancel previous request
    $xhr && $xhr.abort && $xhr.abort();

    $xhr = $.ajax({
        url: url,
        data: {
            selectedUrl: url
        },
        method: 'GET',
        timeout: 10000,
        beforeSend: function () {
            $.spinner().start();
        },
        success: function (response) {
            handleRefinementResponse(url, response);
            toggleFilters(true); //WARNING: might be wrong
        },
        complete: function () {
            $.spinner().stop();
        }
    });
};

const toggleFilters = show => {
    const $filters = $('.js-rolex-watch-filters, .js-rolex-watch-filter-sticky-bar');
    if (show) {
        $filters.addClass('show');
    } else {
        $filters.removeClass('show');
    }
};

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

const filterEvents = () => {
    $('body').on('click', '.js-rolex-refinement-action', function (event) {
        event.preventDefault();
        event.stopPropagation();
        var category = $(event.currentTarget).closest('.refinement');

        var sortingRule = $('body').find('.grid-footer').data('sort-options').ruleId;

        //create a new url with the correct preferences
        if ($(this).data('href').includes('srule')) {
            var refinementUrl = $(this).data('href').replace(/(srule=).*?(&|$)/,'$1' + sortingRule + '$2');
        } else if (!$(this).data('href').includes('srule') && sortingRule != null) {
            var split = $(this).data('href').indexOf('?') !== -1 ? '&' : '?';
            var refinementUrl = $(this).data('href') + split + 'srule=' + sortingRule;
        } else {
            var refinementUrl = $(this).data('href');
        }

        if (category && category.hasClass('refinement-category')) {
            localStorage.setItem('refinement-category', true);
            return;
        }

        toggleFilters(false);
        filterAction(refinementUrl);

    });

    $('body').on('click', '.js-rolex-refinement-button', function (e) {
        e.preventDefault();

        toggleFilters(false);
        filterAction($(this).data('action'));
    });

    $('body').on('click', '.js-rolex-show-filters', function (e) {
        toggleFilters(true);
    });

    $('body').on('click', '.js-rolex-watch-filters-close, .js-rolex-view-results', function (e) {
        toggleFilters(false);
    });
};

const categoryHighlight = () => {
    const brandHeader = document.getElementById('js-brand-header-nav');
    const brandFooter = document.getElementById('js-brand-footer-nav');

    if (brandHeader || brandFooter) {
        const pageUrlHref = document.querySelector("link[rel='canonical']")?.getAttribute("href");
        const brandHeaderMobile = document.querySelector('.js-brand-mobile-menu');

        function applySelectedClass(element) {
            for (var i=0; i<element.length; i++) {
                var child = element[i];
                var childHref = child.getAttribute('href');
                if (childHref === pageUrlHref) {
                    child.classList.add('selected');
                }
            }
        }

        if (brandFooter) {
            const brandFooterChildren = brandFooter.children;
            applySelectedClass(brandFooterChildren);
        }

        if (brandHeader) {
            const brandHeaderChildren = brandHeader.children;
            applySelectedClass(brandHeaderChildren);
        }
        if (brandHeaderMobile) {
            const brandHeaderMobileChildren = brandHeaderMobile.children;
            applySelectedClass(brandHeaderMobileChildren);
        }
    }
};

module.exports = {
    init: () => {
        categoryHighlight();
        filterEvents();
    },
    methods: {
        categoryHighlight: categoryHighlight
    }
};
